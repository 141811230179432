import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import {
    AnteSettings,
    Card,
    Currency,
    CurrencyAmount,
    CurrencyName,
    Deck,
    GameData,
    GameDataInternals,
    GameSettings,
    GameState,
    MessageData,
    Player,
    RaiseOptions,
    User,
    UserPreferences_Output,
    Theme,
} from '../../client';
import { UserService } from '../../client';
import { PortraitHomePageTabType } from '../../components/types/PortraitHomePageTab';

type UserField = keyof User;

interface DeviceProperties {
    isPortrait: boolean;
    isTouchDevice: boolean;
    hasPhysicalKeyboard: boolean;
}

export interface stateInterface {
    areActionHotkeysEnabled: boolean;
    areChatPanelHotkeysEnabled: boolean;
    areRaiseMenuHotkeysEnabled: boolean;
    chatData: MessageData[];
    cashout: {
        cashoutAmount: bigint;
        rakedAmount: bigint;
    };
    eventId: string;
    fetchUser: () => Promise<void>;
    gameData: GameData;
    isAuthenticated: boolean;
    isChatExpanded: boolean;
    isGameSettingsOpen: boolean;
    isRaiseMenuOpen: boolean;
    isTopUpModalOpen: boolean;
    isCashoutModalOpen: boolean;
    userBalances: {
        [chainId: string]: {
            [tokenId: string]: { balance: string; decimals: number };
        };
    };
    updateUserBalances: (balances: {
        [chainId: string]: {
            [tokenId: string]: { balance: string; decimals: number };
        };
    }) => void;
    resetGameState: () => void;
    selectedPortraitHomeTab: PortraitHomePageTabType;
    setSelectedPortraitHomeTab: (tab: PortraitHomePageTabType) => void;
    updateAreActionHotkeysEnabled: (value: boolean) => void;
    updateAreChatPanelHotkeysEnabled: (value: boolean) => void;
    updateAreRaiseMenuHotkeysEnabled: (value: boolean) => void;
    updateChatData: (chatData: MessageData[]) => void;
    updateEventId: (eventId: string) => void;
    updateGameData: (gameData: GameData) => void;
    updateIsAuthenticated: (value: boolean) => void;
    updateIsChatExpanded: (value: boolean) => void;
    updateIsGameSettingsOpen: (value: boolean) => void;
    updateIsRaiseMenuOpen: (value: boolean) => void;
    updateIsTopUpModalOpen: (value: boolean) => void;
    updateIsCashoutModalOpen: (value: boolean) => void;
    updateUser: (user: User) => void;
    updateCashout: (cashout: {
        cashoutAmount: bigint;
        rakedAmount: bigint;
    }) => void;
    updateUserField: (field: UserField, value: string) => void;
    user: User;
    isIOSPWAInputFocused: boolean;
    updateIsIOSPWAInputFocused: (value: boolean) => void;
    deviceProperties: DeviceProperties;
    updateDeviceProperties: (properties: Partial<DeviceProperties>) => void;
    isHamburgerMenuOpen: boolean;
    updateIsHamburgerMenuOpen: (value: boolean) => void;
    isAccountModalOpen: boolean;
    updateIsAccountModalOpen: (value: boolean) => void;
}

export const defaultStore = {
    chatData: [] as MessageData[],
    cashout: {
        cashoutAmount: BigInt(0),
        rakedAmount: BigInt(0),
    },
    eventId: '',
    gameData: {
        game_settings: {
            rake_percentage: 0,
            rake_cap: { decimals: 0, amount: '', display: 0 } as CurrencyAmount,
            rabbit_hunt: false,
            showdown_time: 0,
            turn_time: 0,
            currency: {
                name: CurrencyName.USDT,
                address: '',
                decimals: 0,
            } as Currency,
        } as GameSettings,
        ante_settings: {
            big_blind_value: {
                decimals: 0,
                amount: '',
                display: 0,
            } as CurrencyAmount,
            small_blind_value: {
                decimals: 0,
                amount: '',
                display: 0,
            } as CurrencyAmount,
            min_buy_in: {
                decimals: 0,
                amount: '',
                display: 0,
            } as CurrencyAmount,
            max_buy_in: {
                decimals: 0,
                amount: '',
                display: 0,
            } as CurrencyAmount,
        } as AnteSettings,
        game_id: 0,
        game_host: '',
        chain_id: 0,
        internals: {
            seat_reservations: [],
            deck: {
                cards: [] as Card[],
            } as Deck,
            dealer_seat: 0,
            players_at_beginning_of_hand: [],
            rake_data: {
                bob: {
                    raked_in_game: {
                        decimals: 0,
                        amount: '',
                        display: 0,
                    } as CurrencyAmount,
                    raked_in_round: {
                        decimals: 0,
                        amount: '',
                        display: 0,
                    } as CurrencyAmount,
                },
            },
            small_blind: '',
            big_blind: '',
            utg: '',
            is_heads_up: false,
            should_rake: false,
            first_player: '',
            current_encryption_key: '',
            credit_deltas: {
                bob: { decimals: 0, amount: '', display: 0 } as CurrencyAmount,
            },
            aggressor: '',
            invariants: [],
        } as GameDataInternals,
        game_state: {} as GameState,
        pot: { decimals: 0, amount: '', display: 0 },
        board: [] as Card[],
        current_deciding_player: null,
        players: [] as Player[],
        dealer: null,
        logs: [],
        pending_players: [] as Player[],
        nonce: 0,
        encryption_key: '',
        encrypted_deck: '',
        raise_options: {} as RaiseOptions,
        winner_data: null,
        referred_by: {},
        first_message_time: 0,
        players_waiting_for_blinds: [],
        players_to_set_away: [],
        players_to_add: [],
        target_time: null,
    } as GameData,
    isAuthenticated: false,
    isChatExpanded: false,
    isRaiseMenuOpen: false,
    isTopUpModalOpen: false,
    isGameSettingsOpen: false,
    isCashoutModalOpen: false,
    user: {
        wallet_id: '',
        user_id: '',
        username: '',
        bio: '',
        points: 0,
        preferences: {
            use_four_color_deck: false,
            show_nux: true,
            auto_extra_time: true,
            auto_run_it_twice: true,
            theme: Theme.DEFAULT,
        } as UserPreferences_Output,
    } as User,
    areActionHotkeysEnabled: true,
    areChatPanelHotkeysEnabled: true,
    areRaiseMenuHotkeysEnabled: true,
    selectedPortraitHomeTab: 'Poker' as PortraitHomePageTabType,
    isIOSPWAInputFocused: false,
    deviceProperties: {
        isPortrait:
            typeof window !== 'undefined'
                ? window.innerHeight > window.innerWidth
                : true,
        isTouchDevice:
            typeof window !== 'undefined'
                ? 'ontouchstart' in window || navigator.maxTouchPoints > 0
                : false,
        hasPhysicalKeyboard:
            typeof window !== 'undefined'
                ? !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                      navigator.userAgent,
                  ) &&
                  !('ontouchstart' in window || navigator.maxTouchPoints > 0)
                : true,
    },
    isHamburgerMenuOpen: false,
    isAccountModalOpen: false,
    userBalances: {},
    squid: null,
};

export const useStore = create<stateInterface>()(
    immer(
        devtools((set) => ({
            ...defaultStore,
            updateChatData: (chatData: MessageData[]) =>
                set((state) => {
                    state.chatData = chatData;
                }),
            updateEventId: (eventId: string) =>
                set((state) => {
                    state.eventId = eventId;
                }),
            updateIsAuthenticated: (value: boolean) =>
                set((state) => {
                    state.isAuthenticated = value;
                }),
            updateIsTopUpModalOpen: (value: boolean) =>
                set((state) => {
                    state.isTopUpModalOpen = value;
                }),
            updateIsGameSettingsOpen: (value: boolean) =>
                set((state) => {
                    state.isGameSettingsOpen = value;
                }),
            updateUser: (user: User) =>
                set((state) => {
                    state.user = user;
                }),
            updateUserField: (field: UserField, value: string) =>
                set((state) => {
                    (state.user[field] as string) = value;
                }),
            fetchUser: () => {
                return UserService.getSelf()
                    .then((user) => {
                        set((state) => {
                            state.user = user;
                        });
                    })
                    .catch((error: any) => {
                        console.log('fetchUser: error', error);
                    });
            },
            setSelectedPortraitHomeTab: (tab: PortraitHomePageTabType) =>
                set((state) => {
                    state.selectedPortraitHomeTab = tab;
                }),
            updateGameData: (gameData: GameData) =>
                set((state) => {
                    state.gameData = gameData;
                }),
            updateIsRaiseMenuOpen: (value: boolean) =>
                set((state) => {
                    state.isRaiseMenuOpen = value;
                }),
            updateIsChatExpanded: (value: boolean) =>
                set((state) => {
                    state.isChatExpanded = value;
                }),
            updateAreActionHotkeysEnabled: (value: boolean) =>
                set((state) => {
                    state.areActionHotkeysEnabled = value;
                }),
            updateAreChatPanelHotkeysEnabled: (value: boolean) =>
                set((state) => {
                    state.areChatPanelHotkeysEnabled = value;
                }),
            updateAreRaiseMenuHotkeysEnabled: (value: boolean) =>
                set((state) => {
                    state.areRaiseMenuHotkeysEnabled = value;
                }),
            resetGameState: () =>
                set((state) => {
                    Object.assign(state, {
                        ...defaultStore,
                    });
                }),
            updateIsIOSPWAInputFocused: (value: boolean) =>
                set((state) => {
                    state.isIOSPWAInputFocused = value;
                }),
            updateDeviceProperties: (properties: Partial<DeviceProperties>) =>
                set((state) => {
                    state.deviceProperties = {
                        ...state.deviceProperties,
                        ...properties,
                    };
                }),
            updateIsHamburgerMenuOpen: (value: boolean) =>
                set((state) => {
                    state.isHamburgerMenuOpen = value;
                }),
            updateIsAccountModalOpen: (value: boolean) =>
                set((state) => {
                    state.isAccountModalOpen = value;
                }),
            updateIsCashoutModalOpen: (value: boolean) =>
                set((state) => {
                    state.isCashoutModalOpen = value;
                }),
            updateUserBalances: (balances: {
                [chainId: string]: {
                    [tokenId: string]: { balance: string; decimals: number };
                };
            }) =>
                set((state) => {
                    state.userBalances = balances;
                }),
            updateCashout: (cashout: {
                cashoutAmount: bigint;
                rakedAmount: bigint;
            }) =>
                set((state) => {
                    state.cashout = cashout;
                }),
        })),
    ),
);
