import { ContractCallContext, Multicall } from 'ethereum-multicall';
import { ethers } from 'ethers';
import {
    BUY_IN_TOKENS,
    CHAIN_NAME_TO_CHAIN_ID,
    RPC_URLS,
} from '../../tools/config';
import { ReactNode } from 'react';

type ContractAddress = `0x${string}`;

export type TokenBalance = {
    symbol: string;
    address: string;
    decimals: number;
    balance: string;
    chainId: string | number;
};

export type TokenData = { symbol: string; icon: ReactNode; address: string; decimals: number };

export const NATIVE_EVM_TOKEN_ADDRESS =
    '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee';
export const MULTICALL_ADDRESS = '0xcA11bde05977b3631167028862bE2a173976CA11';
export const multicallAbi = [
    {
        inputs: [
            {
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
        ],
        name: 'getEthBalance',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
];

const getTokensBalanceSupportingMultiCall = async (
    tokens: TokenData[],
    chainId: number,
    userAddress?: ContractAddress,
): Promise<TokenBalance[]> => {
    if (!userAddress) return [];

    const contractCallContext: ContractCallContext[] = tokens.map((token) => {
        const isNativeToken =
            token.address.toLowerCase() ===
            NATIVE_EVM_TOKEN_ADDRESS.toLowerCase();

        return {
            abi: isNativeToken
                ? multicallAbi
                : [
                      {
                          name: 'balanceOf',
                          type: 'function',
                          inputs: [{ name: '_owner', type: 'address' }],
                          outputs: [{ name: 'balance', type: 'uint256' }],
                          stateMutability: 'view',
                      },
                  ],
            contractAddress: isNativeToken ? MULTICALL_ADDRESS : token.address,
            reference: token.symbol,
            calls: [
                {
                    reference: isNativeToken ? 'getEthBalance' : 'balanceOf',
                    methodName: isNativeToken ? 'getEthBalance' : 'balanceOf',
                    methodParameters: [userAddress],
                },
            ],
        };
    });

    const multicallInstance = new Multicall({
        nodeUrl: RPC_URLS[chainId],
        tryAggregate: true,
        multicallCustomContractAddress: MULTICALL_ADDRESS,
    });

    try {
        const { results } = (await multicallInstance.call(
            contractCallContext,
        )) ?? {
            results: {},
        };
        const tokenBalances: TokenBalance[] = [];

        for (const symbol in results) {
            const data = results[symbol].callsReturnContext[0] ?? {};

            const token = tokens.find((t) => t.symbol === symbol);

            if (!token) continue;

            const { decimals, address } = token;

            const mappedBalance: TokenBalance = {
                symbol,
                address,
                decimals,
                balance: BigInt(data.returnValues?.[0].hex ?? 0).toString(),
                chainId,
            };

            tokenBalances.push(mappedBalance);
        }

        return tokenBalances;
    } catch (error) {
        console.log(error);
        return [];
    }
};

export const getAllEvmTokensBalance = async (
    userAddress: string,
): Promise<TokenBalance[]> => {
    try {
        const tokensMulticall: TokenBalance[] = [];

        for (const chainId in BUY_IN_TOKENS) {
            const tokens = BUY_IN_TOKENS[chainId];

            const tokensBalances = await getTokensBalanceSupportingMultiCall(
                Object.values(tokens),
                Number(chainId),
                userAddress as ContractAddress,
            );

            tokensMulticall.push(...tokensBalances);
        }

        return tokensMulticall;
    } catch (error) {
        console.error(error);
        return [];
    }
};
