import { useState, useEffect } from 'react';

export function useIsIOSPWA() {
    const [isIOSPWA, setIsIOSPWA] = useState(false);

    useEffect(() => {
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
        const isInStandaloneMode =
            'standalone' in window.navigator &&
            (window.navigator as any).standalone === true;
        setIsIOSPWA(isIOS && isInStandaloneMode);
    }, []);

    return isIOSPWA;
}

export default useIsIOSPWA;
