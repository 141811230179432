import { HttpLink } from '@apollo/client';
import { ReactNode } from 'react';
import { Address } from 'viem';
import { EthereumCircleColorful, ArbitrumCircleColorful, BaseCircleColorful, UsdcCircleColorful } from '@ant-design/web3-icons';
import { arbitrum, base, blast, ethereum, blastSepolia, Chain } from 'thirdweb/chains';
import { Image } from '@chakra-ui/react';
export const DEFAULT_CHAIN = process.env.NODE_ENV === 'development' ? blastSepolia : blast;
export const THIRDWEB_PROJECT_ID = 'efee4470368da6215ee854fa9b62dbdc';
export const GOLDSKY_BASE_URL = 'https://api.goldsky.com/api/public/project_cly14qj0l0wu501vnc6dq5fjv/subgraphs';
export const GINZA_GAMING_CONTRACT_ADDRESS_BOOK: Record<number, Address> = {
  [blastSepolia.id]: '0x822cE64e7E46271559EAF0Db78c9BF096CD40282',
  [blast.id]: '0x3aE1A45dB9E51C9b9f8EEe6954468A0512d75900'
};
export const TOKEN_CONTRACT_ADDRESS_BOOK: Record<number, Address> = {
  [blastSepolia.id]: '0x4200000000000000000000000000000000000022',
  [blast.id]: '0x4300000000000000000000000000000000000003'
};
export const CCP_GAMING_GQL_ENDPOINTS: Record<number, HttpLink> = {
  [blastSepolia.id]: new HttpLink({
    uri: `${GOLDSKY_BASE_URL}/ccp-blast-testnet-blast-sepolia/5.0.0/gn`
  }),
  [blast.id]: new HttpLink({
    uri: `${GOLDSKY_BASE_URL}/ccp-blast-mainnet-blast/6.0.0/gn`
  })
};
export const USDB_ADDRESS_BOOK: Record<number, Address> = {
  [blastSepolia.id]: '0x4200000000000000000000000000000000000022',
  [blast.id]: '0x4300000000000000000000000000000000000003'
};
export const RPC_URLS: Record<number, string> = {
  [arbitrum.id]: 'https://arb1.arbitrum.io/rpc',
  [base.id]: 'https://base-rpc.publicnode.com	',
  [blast.id]: 'https://rpc.blast.io',
  [blastSepolia.id]: 'https://sepolia.blast.io',
  [ethereum.id]: 'https://ethereum-rpc.publicnode.com'
};
export const BUY_IN_CHAINS: {
  [key: number]: {
    [key: number]: {
      name: string;
      icon: ReactNode;
      id: number;
    };
  };
} = {
  [blast.id]: {
    [ethereum.id]: {
      name: 'Ethereum',
      icon: <EthereumCircleColorful style={{
        fontSize: 18
      }} />,
      id: ethereum.id
    },
    [arbitrum.id]: {
      name: 'Arbitrum',
      icon: <ArbitrumCircleColorful style={{
        fontSize: 18
      }} />,
      id: arbitrum.id
    },
    [base.id]: {
      name: 'Base',
      icon: <BaseCircleColorful style={{
        fontSize: 18
      }} />,
      id: base.id
    },
    [blast.id]: {
      name: 'Blast',
      icon: <Image src="../CryptoLogos/BlastLogo.png" w="18px" h="18px" rounded="full" />,
      id: blast.id
    }
  },
  [blastSepolia.id]: {
    [blastSepolia.id]: {
      name: 'Blast Sepolia',
      icon: <Image src="../CryptoLogos/BlastLogo.png" w="18px" h="18px" rounded="full" />,
      id: blastSepolia.id
    }
  }
};
export const BUY_IN_TOKENS: {
  [chainId: number]: {
    [tokenId: string]: {
      symbol: string;
      icon: ReactNode;
      address: string;
      decimals: number;
    };
  };
} = {
  [ethereum.id]: {
    ETH: {
      symbol: 'ETH',
      icon: <EthereumCircleColorful style={{
        fontSize: 18
      }} />,
      address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
      decimals: 18
    },
    USDC: {
      symbol: 'USDC',
      icon: <UsdcCircleColorful style={{
        fontSize: 18
      }} />,
      address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      decimals: 6
    },
    USDT: {
      symbol: 'USDT',
      icon: <Image src="../CryptoLogos/USDTLogo.png" w="18px" h="18px" rounded="full" />,
      address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      decimals: 6
    }
  },
  [arbitrum.id]: {
    ETH: {
      symbol: 'ETH',
      icon: <EthereumCircleColorful style={{
        fontSize: 18
      }} />,
      address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
      decimals: 18
    },
    USDC: {
      symbol: 'USDC',
      icon: <UsdcCircleColorful style={{
        fontSize: 18
      }} />,
      address: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
      decimals: 6
    },
    USDT: {
      symbol: 'USDT',
      icon: <Image src="../CryptoLogos/USDTLogo.png" w="18px" h="18px" rounded="full" />,
      address: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
      decimals: 6
    }
  },
  [base.id]: {
    ETH: {
      symbol: 'ETH',
      icon: <EthereumCircleColorful style={{
        fontSize: 18
      }} />,
      address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
      decimals: 18
    },
    USDC: {
      symbol: 'USDC',
      icon: <UsdcCircleColorful style={{
        fontSize: 18
      }} />,
      address: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
      decimals: 6
    },
    USDT: {
      symbol: 'USDT',
      icon: <Image src="../CryptoLogos/USDTLogo.png" w="18px" h="18px" rounded="full" />,
      address: '0xfde4C96c8593536E31F229EA8f37b2ADa2699bb2',
      decimals: 6
    }
  },
  [blast.id]: {
    USDB: {
      symbol: 'USDB',
      icon: <Image src="../CryptoLogos/BlastLogo.png" w="18px" h="18px" rounded="full" />,
      address: USDB_ADDRESS_BOOK[blast.id],
      decimals: 18
    }
  },
  [blastSepolia.id]: {
    USDB: {
      symbol: 'USDB',
      icon: <Image src="../CryptoLogos/BlastLogo.png" w="18px" h="18px" rounded="full" />,
      address: USDB_ADDRESS_BOOK[blastSepolia.id],
      decimals: 18
    }
  }
};
export const CHAIN_ID_TO_CHAIN: {
  [key: string]: Chain;
} = {
  '1': ethereum,
  '42161': arbitrum,
  '8453': base,
  '81457': blast,
  '168587773': blastSepolia
};
export const CHAIN_NAME_TO_CHAIN_ID: {
  [key: string]: number;
} = {
  ethereum: 1,
  arbitrum: 42161,
  base: 8453,
  blast: 81457,
  blastSepolia: 168587773
};