import React, { useEffect, useRef } from 'react';
import { useActiveAccount } from 'thirdweb/react';
import { useRouter } from 'next/router';
import { useIsAuthenticated } from './hooks/useIsAuthenticated';
import * as Sentry from '@sentry/nextjs';
import { useStore } from '../tools/zustand';
import { getAllEvmTokensBalance } from './utils/tokenUtils';
import useIsIOSPWA from './hooks/useIsIOSPWA';
export const UserProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const router = useRouter();
  const account = useActiveAccount();
  const isIOSPWA = useIsIOSPWA();
  const {
    isLoggedIn
  } = useIsAuthenticated();
  const setIsAuthenticated = useStore(state => state.updateIsAuthenticated);
  useEffect(() => {
    const handleVisibilityChange = async () => {
      console.log('handleVisibilitychange', document.hidden);
      if (!document.hidden) {
        const isStillAuthenticated = await isLoggedIn();
        console.log({
          isStillAuthenticated
        });
        if (!isStillAuthenticated) {
          console.log('Token expired, need to re-authenticate');
          setIsAuthenticated(false);
          Sentry.captureMessage('Token expired', {
            level: 'warning',
            tags: {
              isIOSPWA: isIOSPWA
            },
            extra: {
              timestamp: new Date().toISOString(),
              userAgent: navigator.userAgent
            }
          });
          // TODO: Trigger a re-authentication process here.. or redirect to a login page
        }
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isIOSPWA, isLoggedIn, setIsAuthenticated]);
  const fetchUser = useStore(state => state.fetchUser);
  const isFetching = useRef(false);
  const updateUserBalances = useStore(state => state.updateUserBalances);
  const userBalances = useStore(state => state.userBalances);
  const fetchBalances = async () => {
    if (!account?.address) return null;
    const balances = await getAllEvmTokensBalance(account.address);
    return balances.reduce((acc, balance) => {
      if (balance.balance !== '0') {
        acc[balance.chainId] = acc[balance.chainId] || {};
        acc[balance.chainId][balance.symbol] = {
          balance: balance.balance,
          decimals: balance.decimals
        };
      }
      return acc;
    }, {} as {
      [chainId: string]: {
        [tokenId: string]: {
          balance: string;
          decimals: number;
        };
      };
    });
  };
  useEffect(() => {
    console.log('UserProvider: account', account, 'isFetching: ', isFetching.current);
    if (!account || isFetching.current) return;
    isFetching.current = true;
    fetchUser().finally(() => isFetching.current = false);
    isLoggedIn();
  }, [account?.address, account, router.pathname]);
  useEffect(() => {
    if (account?.address) {
      const fetchAndUpdateBalances = async () => {
        try {
          const balances = await fetchBalances();
          if (balances && JSON.stringify(balances) !== JSON.stringify(userBalances)) {
            updateUserBalances(balances);
          }
        } catch (error) {
          console.error('Failed to fetch balances:', error);
        }
      };

      // Fetch balances immediately
      fetchAndUpdateBalances();

      // Set up interval to fetch balances every 20 seconds
      const intervalId = setInterval(fetchAndUpdateBalances, 20000);

      // Clean up function to clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }
  }, [account?.address]);
  return <>{children}</>;
};
export default UserProvider;