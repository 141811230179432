import { extendTheme } from '@chakra-ui/react';

import { Button } from './Buttons/ButtonStyles';
import { Text } from './TextStyles';
import { Menu } from './MenuStyles';
import { Modal } from './ModalStyles';
import { Tabs } from './TabStyles';
import { Select } from './SelectStyles';
import { NumberInput } from './NumberInputStyles';
import { Radio } from './RadioStyles';
import { Input } from './InputStyles';
import { Link } from './LinkStyles';
import { Slider } from './SliderStyles';
import { Progress } from './Progress';
import { Table } from './TableStyles';
import { Drawer } from './DrawerStyles';
import { nunitoSans, robotoMono, alumniSans } from '../tools/fonts';

// 1em is equal to 1rem (most browsers), can explicitly define in global styles below
export const theme = extendTheme({
    breakpoints: {
        sm: '30em', // 480px
        md: '48em', // 760.5rem
    },
    styles: {
        global: {
            'html, body': {
                backgroundColor: '#1C1C1C',
                fontFamily: nunitoSans.style.fontFamily,
                fontSize: { base: '1rem', sm: '1rem', md: '1rem' },
            },
        },
    },
    components: {
        Button,
        Drawer,
        Input,
        Link,
        Menu,
        Modal,
        NumberInput,
        Progress,
        Radio,
        Select,
        Slider,
        Table,
        Tabs,
        Text,
    },
    fonts: {
        heading: nunitoSans.style.fontFamily,
        body: nunitoSans.style.fontFamily,
        mono: robotoMono.style.fontFamily,
        alumni: alumniSans.style.fontFamily,
    },
    colors: {
        primaryButtonText: '#FFFFFF',
        brand: {
            ccpGray: '#2B2B29',
            ccpRed: '#C02F2F',
            // UPDATED 6/12/24
            primaryBlue: '#394DFF',
            primaryOrange: '#FF7B02',
            primaryGray: '#12151B',
            secondaryBlueActive: '#4955EB',
            secondaryOrange: '#FFA43C',
            secondaryGreen: '#66F28D',
            secondaryRed: '#F30D0D',
            accentGreen: '#3ACC63',
            secondaryActivePanel: '#6C84D9',
            gray70: '#05070A',
            gray60: 'rgba(47, 51, 58, 0.2)',
            gray50: '#181B20',
            gray40: '#22252B',
            gray30: '#2F333A',
            gray20: '#484E59',
            gray10: '#8A8A8A',
            accentWhite: '#E8E8E8',
            textWhite: '#FFFFFF',
            // LEGACY
            darkestGray: '#12151B',
            darkGray: '#1D1E21',
            lightGray: '#22252B',
            lightestGray: '#2F333A',
            mediumGray: '#181B20',
            mutedGray: '#484E59',
            silverGray: '#BDBDBD',
            lightBlueGray: '#515762',
            mediumBlueGray: '22252B',
            darkBlueGray: '#25282E',
            blueActive: '#4955EB',
            chipGray: '#848484',
            determinationGray: 'rgba(115, 115, 115, 0.2)',
            textPrimary: '#9795FF',
            chatGray: 'rgba(24, 27, 32, 1)',
            tealColor1: '#0BA1A8',
            tealColor2: '#007075',
            showdownTurquoise: 'rgba(11, 161, 168, 1)',
            lightBlue: '#6C84D9',
            bluePurple: 'rgba(151, 149, 255, 1)',
            white10: 'rgba(255, 255, 255, 0.1)',
            white50: 'rgba(255, 255, 255, 0.5)',
            white70: 'rgba(255, 255, 255, 0.7)',
            white80: 'rgba(255, 255, 255, 0.8)',
            white90: 'rgba(255, 255, 255, 0.9)',
            winnerGreen: '#66F28D',
            vividGreen: 'rgba(102, 242, 141, 1)',
            vividOrange: 'rgba(255, 164, 60, 1)',
            emptySeatGray: 'rgba(34, 37, 43, 0.8)',
            cardDisplayBackground: '#05070A',
            pendingYellow: '#FFA500',
            null: '#6B6B6B',
            black: '#000000',
        },
    },
    semanticTokens: {
        // borders: {
        //     "1px": "0px solid",
        // },
        space: {
            '4': '0.625rem',
        },
    },
});
